import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import tvSignalsIcon from '../../../../images/logo.svg';
import tradingViewIcon from '../../../../images/tvlogo.svg';
// import binanceIcon from '../../../../images/binancelogo.svg';
// import tinkoffIcon from '../../../../images/tinkofflogo.svg';
import alorIcon from '../../../../images/alorlogo.svg';
import HomePageButton from '../homePageButton/HomePageButton';
import HomePageTitle from '../homePageTitle/HomePageTitle';
import getStringKey from '../../../../utils/getStringKey';
import HowToStartItem, { HowToStartObject } from './howToStartItem/HowToStartItem';
import styles from './HowToStartBlock.module.scss';

interface Props {
    className?: string;
}

const HowToStartBlock: FC<Props> = ({ className }) => {
    const { t } = useTranslation('homePage');

    const howToStartItems: HowToStartObject[] = [
        { title: t('how_to_start_block.item_tvs'), imgSources: [tvSignalsIcon] },
        { title: t('how_to_start_block.item_api'), imgSources: [alorIcon] },
        { title: t('how_to_start_block.item_tv'), imgSources: [tradingViewIcon] }
    ];

    return (
        <div className={classNames(styles.container, className)}>
            <HomePageTitle superTitle={t('how_to_start_block.super_title')}>
                {t('how_to_start_block.title')}
            </HomePageTitle>
            <div className={styles.items}>
                {howToStartItems.map((howToStartItem, index) => (
                    <HowToStartItem item={howToStartItem} key={getStringKey(index, 'howToStartItem')} />
                ))}
            </div>
            <p className={styles.buttonString}>
                <HomePageButton />
            </p>
        </div>
    );
};

export default HowToStartBlock;
